<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Navigation />
    <!-- 黑白上色 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>
        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="AI助手-黑白图片上色" />
            <ai_upload_img @getImg="getImg" />
            <!-- <div class="flex">
              <p class="upLoad_text">链接地址</p>
              <div class="upload_box">
                <el-input v-model="contentData.origin_url" placeholder="请输入图片链接地址"></el-input>

              </div>
            </div> -->
            <button class="create_but" @click="FileData">生成内容</button>
            <ai_img :type="2" contentName='上色后图片' :contentData="contentData" />
            <aiNum title="计费规则：本次生成黑白图像上色预计消费0.1元" :visible="aiNumShow" @close="aiNumShow = false"
              @postData="postData" />
            <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
          </div>
          <aiNav />

        </div>

      </div>


    </div>
    <footerBot />
  </div>
</template>

<script>
import ai_upload_img from '@/components/ai_upload_img'
import ai_title from '@/components/ai_title'
import ai_img from '@/components/ai_img'
import aiNav from '@/components/aiNav'
export default {
  mounted() {
    this.getUserInfo()
  },
  components: {
    aiNav,
    ai_upload_img,
    ai_img,
    ai_title
  },
  data() {
    return {
      fileList: [],
      contentData: {
        origin_url: '',
        // cover_url:''
        output_content: '',
      }, //内容
      fullscreenLoading: false,
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    getImg(url) {
      this.contentData.origin_url = url
      console.log('contentData', this.contentData);
    },
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        if (res.data.code) {
          this.$user_info = res.data.data
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
        }
      })
    },
    FileData() {
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
      }
    },
    postData() {
      this.aiNumShow = false
      this.fullscreenLoading = true
      let data = {}
      data.mobile = this.$user_info.mobile
      for (const key in this.contentData) {
        if (key != 'output_content') {
          console.log('index:', key);
          data[key] = this.contentData[key]
        }
      }
      this.curlPost('file/drawing_color', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.contentData.output_content = res.data.data.output_content

          // 扣费
          this.curlPost('/api/users/money_log/spend', {
            ai_id: res.data.data.id,
            amount: res.data.data.money,
          }).then(res => {
            if (res.data.code) {
              this.getUserInfo()
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
        this.fullscreenLoading = false
      })
    }

  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>